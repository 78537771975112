<!-- 外协订单组件 -->
<template>
  <div>
    <el-dialog
        width="40%"
        center
        :visible="visible"
        :lock-scroll="false"
        :destroy-on-close="true"
        custom-class="ele-dialog-form"
        :title="isUpdate?'外协':'外协'"
        @update:visible="updateVisible">
      <el-form
          ref="form"
          :model="form"
          label-width="100px">

        <el-form-item label="选择外协司机" label-width="100px">
          <el-select
              clearable
              v-model="form.status"
              placeholder="请选择"
              class="ele-fluid">
            <el-option label="张三 13100000000 粤A123456" value="1"/>
            <el-option label="张三 13100000000 粤A123456" value="2"/>
            <el-option label="张三 13100000000 粤A123456" value="3"/>
          </el-select>
        </el-form-item>
      </el-form>


      <div slot="footer">
        <el-button
            @click="updateVisible(false)">取消
        </el-button>
        <el-button
            style="margin-left: 20px;"
            type="primary"
            :loading="loading"
            @click="save">确定
        </el-button>
      </div>
    </el-dialog>

  </div>


</template>

<script>

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({status: 1}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

    };
  },
  watch: {
    data() {
      if (this.data) {
        console.log(this.data)
        this.form = Object.assign({}, this.data);
        this.isUpdate = true;
      } else {
        this.form = {};
        this.isUpdate = false;
      }
    }
  },
  mounted() {

  },
  methods: {
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.updateVisible(false);
          this.$emit('done');
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },



  }
}
</script>

<style scoped lang="scss">
.no-multiple {
  // 隐藏多选
  ::v-deep .el-table__header-wrapper .el-checkbox {
    display: none;
  }
}
</style>
